import * as Route from "../../../constants/routes";
import React from "react";

const Footer = () => {

  return (
    <footer className="footer">
      <div className="footer-col-1">
        <h1>Company</h1>
        <a className="about-us-link" href={Route.ABOUT}>
          About Us
        </a>
        <br></br>
        <a className="about-us-link" href={Route.SUPPORT}>
          Support
        </a>
      </div>
      <div className="footer-col-2">
        <span>
            Nexolvo Tech Private Limited &copy;&nbsp;
            {new Date().getFullYear()}, All rights reserved
        </span>
      </div>
      <div className="footer-col-3">
        <h1>Legal</h1>
        <a className="about-us-link" href={Route.T_AND_C}>
          Terms and Conditions
        </a>
        <br></br>
        <a className="about-us-link" href={Route.CANCELLATION}>
          Cancellation and Refund policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
