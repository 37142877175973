import { ArrowRightOutlined } from '@ant-design/icons';
import {
	useDocumentTitle, useScrollTop
} from '../../../hooks';
import bannerImg from '../../../images/banner4.jpeg';
import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
	useDocumentTitle('About');
	useScrollTop();

	return (
		<main className="content">
			<div className="home">
				<div className="banner">
					<div className="banner-desc">
						<h1 className="text-thin">
							The&nbsp;
							<strong>lean start-up</strong>
						</h1>
						<p className='banner-text'>
							We are a small company with huge potential, our aim is to make people's lives easier by providing smart solutions to everyday problems. We started our company in 2023 and we are growing in a good pace. If you have any common problem and want a solution then look no further.
						</p>
						<br />
						<Link to='/support' className="button">
							Contact Us &nbsp;
							<ArrowRightOutlined />
						</Link>
					</div>
					<div className="banner-img"><img src={bannerImg} alt="" /></div>
				</div>
			</div>
		</main>
	);
};

export default About;
