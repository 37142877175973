import { ArrowRightOutlined } from '@ant-design/icons';
import {
	useDocumentTitle, useScrollTop
} from '../../../hooks';
import bannerImg from '../../../images/banner.webp';
import bannerImg2 from '../../../images/banner2.webp';
import bannerImg3 from '../../../images/banner3.webp';
import React from 'react';
import { Link } from 'react-router-dom';


const Home = () => {
	useDocumentTitle('neXolvo | Home');
	useScrollTop();

	return (
		<main className="content">
			<div className="home">
				<div className="banner">
					<div className="banner-desc">
						<h1 className="text-thin">
							Providing&nbsp;
							<strong>next-gen solutions</strong>
						</h1>
						<p className='banner-text'>
							We excel at providing smart and innovative solutions for common problems.
						</p>
						<br />
						<Link to='/products' className="button">
							See our products &nbsp;
							<ArrowRightOutlined />
						</Link>
					</div>
					<div className="banner-img"><img src={bannerImg} alt="" /></div>
				</div>
				<div className="display">
					<div className="display-header">
						<h1>Work smarter with Nexolvo</h1>
					</div>
					<div className="display-header">
						<p className='terms-text'>Work with very high skilled techies who are not just programmers but are problem solvers. You can rest assured that your product will be in safe hands.</p>
					</div>
				</div>
				<div className="banner">
					<div className="banner-img-left"><img src={bannerImg2} alt="" /></div>
					<div className="banner-desc">
						<h1 className="text-thin">
							Age of&nbsp;
							<strong>AI</strong>
						</h1>
						<p className='banner-text'>
							We integrate <strong>AI</strong> for most of our products and quickly turn around things effectively.
						</p>
						<br />
						<Link to='/support' className="button">
							Learn more &nbsp;
							<ArrowRightOutlined />
						</Link>
					</div>
				</div>
				<div className="banner">
					<div className="banner-desc">
						<h1 className="text-thin">
							Connecting&nbsp;
							<strong>like minded</strong>
							&nbsp;people
						</h1>
						<p className='banner-text'>
							One of our core values is establishing strong connections with people which helps us understand the problems better and provide solutions accurately.
						</p>
						<br />
						<Link to='/support' className="button">
							Contact us &nbsp;
							<ArrowRightOutlined />
						</Link>
					</div>
					<div className="banner-img"><img src={bannerImg3} alt="" /></div>
				</div>
			</div>
		</main>
	);
};

export default Home;
