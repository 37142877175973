import { HOME } from "../../constants/routes";
import PropType from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Navigation = (props) => {
  const { isLoading, logo } = props;
  // const { pathname } = useLocation();

  const onClickLink = (e) => {
    if (isLoading) e.preventDefault();
  };

  return (
    <nav className="mobile-navigation">
      <div className="mobile-navigation-main">
        <div className="mobile-navigation-logo">
          <Link onClick={onClickLink} to={HOME}>
            <img alt="Logo" src={logo} />
          </Link>
        </div>

        {/* <ul className="mobile-navigation-menu">
                    <>
                        {pathname !== SIGNIN && (
                            <li className="mobile-navigation-item">
                                <Link
                                    className="navigation-menu-link"
                                    onClick={onClickLink}
                                    to={SIGNIN}
                                >
                                    Sign In
                                </Link>
                            </li>
                        )}
                    </>
                </ul> */}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  isLoading: PropType.bool.isRequired,
};

export default Navigation;
