import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    phno: '',
    phnoVerified: false,
    ftuj: false,
    name: '',
    email: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        fullAddress: '',
        latitude: 0,
        longitude: 0,
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
    },
    currentPosition: {},
    receiveEmails: false,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.phno = action.payload.phno;
            state.name = action.payload.name;
            state.email = action.payload.email;
            // state.receiveEmails = action.payload.receiveEmails;
        },
        setAddress: (state, action) => {
            const { addressLine1, addressLine2, city, country, zipCode, latitude, longitude } = action.payload;
            if (addressLine1) state.address.addressLine1 = addressLine1;
            if (addressLine2) state.address.addressLine2 = addressLine2;
            if (city) state.address.city = city;
            if (action.payload.state) state.address.state = action.payload.state;
            if (country) state.address.country = country;
            if (zipCode) state.address.zipCode = zipCode;
            if (latitude) state.address.latitude = latitude;
            if (longitude) state.address.longitude = longitude;
            if (city && action.payload.state && country && zipCode) state.address.fullAddress = `${addressLine1 || 'unknown'}, ${addressLine2 || 'unknown'}, ${city}, ${action.payload.state}, ${country}, ${zipCode}`;
        },
        setCurrentPosition: (state, action) => {
            state.currentPosition = action.payload;
        },
        setFtuj: (state, action) => {
            state.ftuj = action.payload;
        },
        setPhnoVerified: (state, action) => {
            state.phnoVerified = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const { setUserDetails, setAddress, setCurrentPosition, setFtuj, setPhnoVerified, resetUserData } = slice.actions;

export default slice.reducer;
