import React from 'react';
import {
	useDocumentTitle, useScrollTop
} from '../../../hooks';

const TermsAndConditions = () => {
    useDocumentTitle('T&C');
	useScrollTop();

    return (
        <main className="content">
            <div className='home'>
                <div className="display">
                    <div className="display-header">
                        <h1>Terms and Conditions</h1>
                    </div>
                    <span className='terms-text'>
                        Last updated on Dec 23
                    </span>
                    <br></br>
                    <span className='terms-text'>
                        For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean NEXOLVO TECH PRIVATE LIMITED, whose registered/operational office is #306, 3rd Floor, Pearlite Lakeside Apartments, opposite to Govt primary school, Parappana Agrahara, Bengaluru Urban KARNATAKA 560068. "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
                    </span>
                    <h2>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</h2>
                    <ul>
                        <li><span className='terms-text'>The content of the pages of this website is subject to change without notice.</span></li>
                        <li><span className='terms-text'>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</span></li>
                        <li><span className='terms-text'>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</span></li>
                        <li><span className='terms-text'>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</span></li>
                        <li><span className='terms-text'>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</span></li>
                        <li><span className='terms-text'>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</span></li>
                        <li><span className='terms-text'>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</span></li>
                        <li><span className='terms-text'>You may not create a link to our website from another website or document without NEXOLVO TECH PRIVATE LIMITED’s prior written consent.</span></li>
                        <li><span className='terms-text'>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.</span></li>
                        <li><span className='terms-text'>We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</span></li>
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default TermsAndConditions;