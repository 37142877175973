import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    theme: 'system',
    // serverUrl: 'http://192.168.0.121:9090/api/v1'
    // serverUrl: 'https://nucleus-3x63.onrender.com/api/v1'
    serverUrl: 'https://www.easygigz.com/api/v1'
}

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setServerUrl: (state, action) => {
            state.serverUrl = action.payload;
        },
    },
});

export const { setTheme, setServerUrl } = slice.actions;

export default slice.reducer;
