// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import './style.css';

// const Header = () => (
// 	<header className='header'>
// 		{/* <img src="../../assets/preact-logo-inverse.svg" alt="Preact Logo" height="32" width="32" /> */}
// 		<h1>Nexolvo</h1>
// 		<nav>
// 			<NavLink className={({ isActive, isPending }) =>
// 				isPending ? "" : isActive ? 'active' : ""
// 			} to="/">
// 				Home
// 			</NavLink>
// 			<NavLink className={({ isActive, isPending }) =>
// 				isPending ? "" : isActive ? 'active' : ""
// 			} to="/about">
// 				About
// 			</NavLink>
// 		</nav>
// 	</header>
// );

// export default Header;

/* eslint-disable indent */
import * as ROUTE from '../../constants/routes';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
	Link, NavLink, useLocation
} from 'react-router-dom';
import logo from '../../images/logo.png';
import MobileHeader from './MobileHeader';

const Header = () => {
	const navbar = useRef(null);
	const { pathname } = useLocation();

	const store = useSelector((state) => ({
		isLoading: state.appStatus.loading
	}));

	const scrollHandler = () => {
		if (navbar.current && window.screen.width > 480) {
			if (window.pageYOffset >= 70) {
				navbar.current.classList.add('is-nav-scrolled');
			} else {
				navbar.current.classList.remove('is-nav-scrolled');
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler);
		return () => window.removeEventListener('scroll', scrollHandler);
	}, []);

	const onClickLink = (e) => {
		if (store.isLoading) e.preventDefault();
	};

	if (window.screen.width <= 800) {
		return (
			<MobileHeader
				// eslint-disable-next-line react/jsx-props-no-spreading
				logo={logo}
				{...store}
				pathname={pathname}
			/>
		);
	}
	return (
		<nav className="navigation" ref={navbar}>
			<div className="logo">
				<Link onClick={onClickLink} to="/"><img alt="Logo" src={logo} /></Link>
			</div>
			<ul className="navigation-menu-main">
				<li><NavLink activeClassName="navigation-menu-active" exact to={ROUTE.HOME}>Home</NavLink></li>
				<li><NavLink activeClassName="navigation-menu-active" to={ROUTE.PRODUCTS}>Products</NavLink></li>
			</ul>
			{/* <ul className="navigation-menu">
				<li className="navigation-action">
					{pathname !== ROUTE.SIGNUP && (
						<Link
							className="button button-small"
							onClick={onClickLink}
							to={ROUTE.SIGNUP}
						>
							Sign Up
						</Link>
					)}
					{pathname !== ROUTE.SIGNIN && (
						<Link
							className="button button-small button-muted margin-left-s"
							onClick={onClickLink}
							to={ROUTE.SIGNIN}
						>
							Sign In
						</Link>
					)}
				</li>
			</ul> */}
		</nav>
	);
};

export default Header;