import { ArrowRightOutlined } from '@ant-design/icons';
import {
	useDocumentTitle, useScrollTop
} from '../../../hooks';
import bannerImg from '../../../images/banner5.png';
import React from 'react';
import { Link } from 'react-router-dom';


const Products = () => {
	useDocumentTitle('Products');
	useScrollTop();

	return (
		<main className="content">
			<div className="home">
				<div className="banner">
					<div className="banner-desc">
						<h1 className="text-thin">
							easy
							<strong>gigz</strong>
						</h1>
						<p className='banner-text'>
							This is our flagship application which is basically freelancing on steroids. Anybody can hop on and make some quick bucks by doing small chores that people post in our app.
						</p>
						<br />
						<Link to='/support' className="button">
							Download app &nbsp;
							<ArrowRightOutlined />
						</Link>
					</div>
					<div className="banner-img"><img src={bannerImg} alt="" /></div>
				</div>
			</div>
		</main>
	);
};

export default Products;
