import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth: {
        userId: '',
        token: '',
    },
    device: {
        token: '',
    },
    isLoggedIn: false,
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSuccess: (state, action) => {
            const { userId, token } = action.payload;
            state.auth.userId = userId;
            state.auth.token = token;
            state.isLoggedIn = true;
        },
        authFailure: (state) => {
            state.auth.userId = '';
            state.auth.token = '';
            state.isLoggedIn = false;
        },
        setDeviceToken: (state, action) => {
            state.device.token = action.payload;
        },
        authLogout: () => initialState,
    },
});

export const { authSuccess, authFailure, setDeviceToken, authLogout } = slice.actions;

export default slice.reducer;
