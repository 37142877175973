import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

