import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from './NoPage';
import Header from './Header';
import Home from '../screens/home';
import About from '../screens/about';
import Footer from '../components/common/Footer';
import TermsAndConditions from '../screens/termsandconditions';
import CancellationPolicy from '../screens/cancellationpolicy';
import Products from '../screens/products';
import * as Router from '../../constants/routes';
import Support from '../screens/support';

const RootNavigator = () => {

    return (
        <div id="app">
            <BrowserRouter>
                <Header />
                <main>
                    <Routes>
                        <Route
                            index
                            path="/"
                            element={<Home />}
                        />
                        <Route
                            path={Router.ABOUT}
                            element={<About />}
                        />
                        <Route
                            path={Router.PRODUCTS}
                            element={<Products />}
                        />
                        <Route
                            path={Router.T_AND_C}
                            element={<TermsAndConditions />}
                        />
                        <Route
                            path={Router.CANCELLATION}
                            element={<CancellationPolicy />}
                        />
                        <Route
                            path={Router.SUPPORT}
                            element={<Support />}
                        />
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </main>
                <Footer />
            </BrowserRouter>
        </div>
    );
};

export default RootNavigator;