import { useDocumentTitle, useScrollTop } from "../../../hooks";
import bannerImg from "../../../images/banner6.png";
import React from "react";

const Support = () => {
  useDocumentTitle("Support");
  useScrollTop();

  return (
    <main className="content">
      <div className="home">
        <div className="banner">
          <div className="banner-desc">
            <h1 className="text-thin">Contact us at</h1>
            <h1 className="text-thin">
              <strong>support@nexolvo.com</strong>
            </h1>
          </div>
          <div className="banner-img">
            <img src={bannerImg} alt="" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Support;
