import { combineReducers } from 'redux';
import authReducer from './authReducer';
import statusReducer from './statusReducer';
import settingsReducer from './settingsReducer';
import userReducer from './userReducer';

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    appStatus: statusReducer,
    settings: settingsReducer,
});
