import React from 'react';
import {
	useDocumentTitle, useScrollTop
} from '../../../hooks';

const CancellationPolicy = () => {
	useDocumentTitle('Policy');
	useScrollTop();

    return (
        <main className="content">
            <div className='home'>
                <div className="display">
                    <div className="display-header">
                        <h1>Cancellation and Refund Policy</h1>
                    </div>
                    <span className='terms-text'>
                        Last updated on Dec 23 2023
                    </span>
                    <br></br>
                    <span className='terms-text'>
                        NEXOLVO TECH PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
                    </span>
                    <ul>
                        <li><span className='terms-text'>Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</span></li>
                        <li><span className='terms-text'>NEXOLVO TECH PRIVATE LIMITED does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</span></li>
                        <li><span className='terms-text'>In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products.</span></li>
                        <li><span className='terms-text'>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</span></li>
                        <li><span className='terms-text'>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</span></li>
                        <li><span className='terms-text'>In case of any Refunds approved by the NEXOLVO TECH PRIVATE LIMITED, it’ll take 6-8 days for the refund to be processed to the end customer.</span></li>
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default CancellationPolicy;